import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { SubscriptionAddressInterface } from "../index";
import Field from "../../../Forms/Field";
import { LocalizedContext } from "../../../../services/LocalizedContextService";
import Button from "../../../Button";
import { updateSubscriptionAddress } from "../../../../services/salesforce/Subscription";

import "./styles.scss";
import { sendErrorEvents } from "../../../../utils/sendErrorEvents";

const AddressForm = ({
  orderID,
  data,
  addressType,
  handleCancel,
  closeOnSubmit,
  customerNo,
  isSubscriptionOrder,
  fetchSubscriptionsData
}: {
  orderID: string;
  data: SubscriptionAddressInterface;
  addressType: string;
  handleCancel: any;
  closeOnSubmit: () => void;
  customerNo: string;
  isSubscriptionOrder?: boolean;
  fetchSubscriptionsData: (customerNo: string) => Promise<any>;
}) => {
  const { firstName, lastName, address1, address2, countryCode, phone, city, stateCode, postalCode } = data;

  const formPlaceholders = useContext(LocalizedContext).sanityLabels?.formPlaceholders;
  const { ctaLabels: labels, formsLabels } = useContext(LocalizedContext).sanityLabels || {};
  const errorMessages = useContext(LocalizedContext).sanityLabels?.errorMessages;

  const formFields: any = [
    {
      type: "text",
      name: "firstName",
      label: formsLabels?.firstName,
      defaultValue: firstName,
      rules: {
        required: errorMessages?.generalError
      },
      message: errorMessages?.validFirstName
    },
    {
      type: "text",
      name: "lastName",
      label: formsLabels?.lastName,
      defaultValue: lastName,
      rules: {
        required: errorMessages?.generalError
      },
      message: errorMessages?.validLastName
    },
    {
      type: "text",
      name: "address1",
      label: formsLabels?.address1,
      defaultValue: address1,
      rules: {
        required: errorMessages?.generalError
      },
      message: errorMessages?.validAddress1
    },
    {
      type: "text",
      name: "address2",
      label: formsLabels?.address2,
      defaultValue: address2,
      rules: {
        required: false
      },
      message: ""
    },

    {
      type: "text",
      name: "countryCode",
      label: formsLabels?.country,
      placeholder: formPlaceholders?.country,
      defaultValue: countryCode?.value || countryCode,
      rules: {
        required: errorMessages?.generalError,
        minLength: 2,
        maxLength: 3
      },
      message: errorMessages?.validCountry
    },
    {
      type: "text",
      name: "stateCode",
      label: formsLabels?.state,
      placeholder: formPlaceholders?.state,
      defaultValue: stateCode,
      rules: {
        required: errorMessages?.generalError,
        minLength: 2,
        maxLength: 3
      },
      message: errorMessages?.validState
    },
    {
      type: "text",
      name: "city",
      label: formsLabels?.city,
      defaultValue: city,
      rules: {
        required: errorMessages?.generalError
      },
      message: errorMessages?.validCity
    },
    {
      type: "text",
      name: "postalCode",
      label: formsLabels?.zipCode,
      placeholder: formPlaceholders?.zipCode,
      defaultValue: postalCode,
      rules: {
        required: errorMessages?.generalError
      },
      message: errorMessages?.validZipCode
    },
    {
      type: "text",
      name: "phone",
      label: formsLabels?.phone,
      placeholder: formPlaceholders?.phone,
      defaultValue: phone,
      rules: {
        required: errorMessages?.generalError,
        maxLength: 2147483647,
        pattern: /^\+?\d{0,1}\s?-?\(?(\d{3})\)?\s?-?(\d{3})\s?-?(\d{4})$/
      },
      message: errorMessages?.validPhone
    }
  ];

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    reset();
  }, []);

  const handleSubmitClick = () => {
    sendErrorEvents(errors, formFields);
  };
  const onSubmit = async (data: any) => {
    // submit new address to API for addressType (shipping or billing)
    try {
      const formData = {
        ...data,
        customerNo,
        addressType,
        valid: true,
        ...(isSubscriptionOrder ? { subscriptionId: orderID } : { oid: orderID })
      };
      await updateSubscriptionAddress(formData);
      fetchSubscriptionsData(customerNo);
    } catch (err) {
      console.log(err);
    } finally {
      closeOnSubmit();
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="address-form__wrapper">
      {formFields.map((field: any, index: number) => (
        <div className="grid-row" key={index}>
          <div className="form-group">
            <Field key={field.name} {...field} registerInput={register} />
            <div className="col-md">
              <ErrorMessage errors={errors} name={field.name} render={({ message }) => <p>{message}</p>} />
            </div>
          </div>
        </div>
      ))}
      <div className="address-form-buttons">
        <Button
          type="reset"
          variant="nonary"
          form="br-50"
          className="address-form__cancel button button-tertiary button-size-sm"
          onClick={handleCancel}
        >
          {labels?.cancel}
        </Button>
        <Button
          className="address-form__submit"
          type="submit"
          variant="nonary"
          form="br-50"
          onClick={handleSubmitClick}
        >
          {labels?.save}
        </Button>
      </div>
    </form>
  );
};

export default AddressForm;
