import React, { useState, useEffect } from "react";
import AddressCard from "../AddressBlocks/AddressCard";
import AddressEdit from "../AddressBlocks/AddressEdit";
import ModalBlock from "../../Modal";
import ProductBlock from "../ProductBlocks/ProductBlock";
import { ProductsDataInterface, AddressInterface } from "../../OrderHistory/index";
import { getSubscriptionDetailsById } from "../../../../services/salesforce/Subscription";
import { SubscriptionListItemInterface } from "..";

import "./styles.scss";

const ModifyOrderBlock = ({
  ID,
  isSubscriptionOrder = false,
  productData,
  locale,
  customerNo,
  billingAddress,
  shippingAddress,
  orderInfo: prefetchOrderInfo,
  fetchSubscriptionsData,
  handleModalClose
}: {
  ID: string;
  isSubscriptionOrder: boolean;
  productData: ProductsDataInterface[];
  locale: string;
  customerNo: string;
  billingAddress: AddressInterface;
  shippingAddress: AddressInterface;
  upcomingOrder?: boolean;
  orderInfo: SubscriptionListItemInterface;
  fetchSubscriptionsData: (customerNo: string) => Promise<any>;
  handleModalClose: () => void;
}) => {
  const [orderData, setOrderData] = useState({
    ProductList: {
      ID: "",
      billingAddress: {},
      shippingAddress: {},
      createdAt: ""
    },
    currentCreditCard: {},
    ShippingMethodName: ""
  });

  const orderInfo = orderData?.ProductList || {};
  const payment = orderData?.currentCreditCard || {};

  const orderDate = orderInfo.createdAt
    ? new Date(orderInfo.createdAt).toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric"
      })
    : "";

  //modal for modify Address
  const [showModalModifyBillingAddress, setShowModalModifyBillingAddress] = useState(false);
  const handleCloseBilling = () => setShowModalModifyBillingAddress(false);
  const handleSubmitBilling = () => {
    setShowModalModifyBillingAddress(false);
    handleModalClose();
  };

  //modal for modify Address
  const [showModalModifyShippingAddress, setShowModalModifyShippingAddress] = useState(false);
  const handleCloseShipping = () => setShowModalModifyShippingAddress(false);
  const handleSubmitShipping = () => {
    setShowModalModifyShippingAddress(false);
    handleModalClose();
  };

  const fetchOrderDetails = async ({ subscriptionId, customerNo }: { subscriptionId: string; customerNo: string }) => {
    try {
      const response = await getSubscriptionDetailsById({ subscriptionId, customerNo });
      let _newResponse = {
        ...response
      };
      if (!isSubscriptionOrder) {
        _newResponse = {
          ...response,
          ProductList: {
            ...prefetchOrderInfo
          }
        };
      }
      setOrderData({
        ..._newResponse
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (ID && customerNo) {
      fetchOrderDetails({ subscriptionId: ID, customerNo });
    }
  }, [ID, customerNo]);

  return (
    <div className="modify-subscription-order">
      <div className="text__size-h3 smb-24 smb-lg-36">Subscription</div>
      <div className="order-date">{orderDate}</div>
      <div className="modify-subscription-order__block">
        <div className="text__size-h5">Billing Address</div>
        <AddressCard
          orderID={ID}
          addressType="billing"
          address={orderInfo.billingAddress || billingAddress}
          handleChange={() => setShowModalModifyBillingAddress(true)}
        />
      </div>
      <div className="modify-subscription-order__block">
        <div className="text__size-h5">Shipping Address</div>
        <AddressCard
          orderID={ID}
          addressType="shipping"
          address={orderInfo.shippingAddress || shippingAddress}
          handleChange={() => setShowModalModifyShippingAddress(true)}
        />
      </div>
      <div className="modify-subscription-order__block">
        <div className="text__size-h5 smb-16">Shipping Method</div>
        <div className="">{orderData.ShippingMethodName}</div>
      </div>
      <div className="modify-subscription-order__block subscription-products">
        <ProductBlock
          subscriptionOrderID={ID}
          orderProducts={orderInfo.products}
          productData={productData}
          isSubscriptionOrder={isSubscriptionOrder}
          locale={locale}
          shippingMethod={orderData.ShippingMethodName}
          shippingCost={orderData.ShippingCost}
          customerNo={customerNo}
          fetchSubscriptionsData={fetchSubscriptionsData}
          handleModalClose={handleModalClose}
        />
      </div>
      {isSubscriptionOrder && (
        <div className="modify-subscription-order__block subscription-payment">
          <div className="text__size-h5 smb-16">Payment information</div>
          <div className="grid-row">
            <div className="payment-title highlighted">Card holder name</div>
            <div className="payment-info">{payment.holderName}</div>
          </div>
          <div className="grid-row">
            <div className="payment-title highlighted">Card type</div>
            <div className="payment-info">{payment.type}</div>
          </div>
          <div className="grid-row">
            <div className="payment-title highlighted">Card number</div>
            <div className="payment-info">{payment.number}</div>
          </div>
          <div className="grid-row">
            <div className="payment-title highlighted">Expiration date</div>
            <div className="payment-info">
              {payment.expMonth}/{payment.expYear}
            </div>
          </div>
        </div>
      )}

      <ModalBlock
        showModal={showModalModifyBillingAddress}
        handleSubmit={handleSubmitBilling}
        handleClose={handleCloseBilling}
        ctaConfirm=""
        ctaCancel=""
        isSecondary={true}
      >
        <AddressEdit
          orderID={orderInfo.ID}
          data={orderInfo.billingAddress || billingAddress}
          addressType={"billing"}
          handleCancel={handleCloseBilling}
          closeOnSubmit={handleSubmitBilling}
          customerNo={customerNo}
          fetchSubscriptionsData={fetchSubscriptionsData}
          isSubscriptionOrder={isSubscriptionOrder}
        />
      </ModalBlock>
      <ModalBlock
        showModal={showModalModifyShippingAddress}
        handleSubmit={handleSubmitShipping}
        handleClose={handleCloseShipping}
        ctaConfirm=""
        ctaCancel=""
        isSecondary={true}
      >
        <AddressEdit
          orderID={orderInfo.ID}
          data={orderInfo.shippingAddress || shippingAddress}
          addressType={"shipping"}
          handleCancel={handleCloseShipping}
          closeOnSubmit={handleSubmitShipping}
          customerNo={customerNo}
          fetchSubscriptionsData={fetchSubscriptionsData}
          isSubscriptionOrder={isSubscriptionOrder}
        />
      </ModalBlock>
    </div>
  );
};

export default ModifyOrderBlock;
