import React, { useContext, useState } from "react";
import SanityImage from "gatsby-plugin-sanity-image";
import { LocalizedContext } from "../../../../services/LocalizedContextService";
import Button from "../../../Button";
import Accordion from "react-bootstrap/Accordion";
import ContextAwareAccordionToggle from "../../../ContextAwareAccordionToggle";
import UpcomingOrder from "./UpcomingOrder";
import ModalBlock from "../../Modal";
import ModifyOrderBlock from "./ModifyOrderBlock";
import { AddressInterface, ProductsDataInterface } from "../../OrderHistory/index";
import { SubscriptionListItemInterface, SubscriptionProductInterface, SubscriptionsListInterface } from "../index";
import ReactivateSubscription from "./ReactivateSubscriptionBlock";
import {
  pauseSubscription,
  subscriptionCancelRenewal,
  subscriptionReactivate
} from "../../../../services/salesforce/Subscription";

import "./styles.scss";
import { IMAGE_SIZES } from "../../../../constants";

const SubscriptionBlock = ({
  key,
  orderInfo,
  upcomingOrders,
  productData,
  locale,
  customerNo,
  billingAddress,
  shippingAddress,
  fetchSubscriptionsData
}: {
  key: number;
  orderInfo: SubscriptionsListInterface;
  upcomingOrders: SubscriptionListItemInterface[];
  productData: any;
  locale: string;
  customerNo: string;
  billingAddress: AddressInterface;
  shippingAddress: AddressInterface;
  fetchSubscriptionsData: (customerNo: string) => Promise<any>;
}) => {
  const { sanitySiteSettings } = useContext(LocalizedContext);

  const orderDate = orderInfo.createdAt
    ? new Date(orderInfo.createdAt).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })
    : "";

  const renderUpcomingOrders = (orderID: string, ordersList: any) => {
    return (
      <Accordion className="upcoming-orders-container">
        <ContextAwareAccordionToggle eventKey={`${orderID}`}>
          <div className="upcoming-orders__title highlighted">See Upcoming Orders</div>
        </ContextAwareAccordionToggle>
        <Accordion.Collapse eventKey={`${orderID}`}>
          {ordersList && ordersList.length ? (
            <ul className="upcoming-order__list">
              {ordersList.filter(Boolean).map((item: SubscriptionListItemInterface) => {
                return (
                  <li className="upcoming-order__list-item" key={item.ID}>
                    <UpcomingOrder
                      ID={item.ID}
                      customerNo={item.customerNo}
                      isSubscriptionOrder={false}
                      locale={locale}
                      orderInfo={item}
                      createdAt={item.createdAt}
                      productData={productData}
                      billingAddress={billingAddress}
                      shippingAddress={shippingAddress}
                      fetchSubscriptionsData={fetchSubscriptionsData}
                    />
                  </li>
                );
              })}
            </ul>
          ) : (
            <div>At the moment you don't have refill orders.</div>
          )}
        </Accordion.Collapse>
      </Accordion>
    );
  };

  //modal for modify order
  const [showModalModify, setShowModalModify] = useState(false);
  const handleClose = () => setShowModalModify(false);
  const handleSubmit = () => {
    setShowModalModify(false);
  };

  //modal for pause/reactivate
  const [subscriptionActive, setSubscriptionActive] = useState(Boolean(orderInfo.status !== "paused"));
  const [showModalReactivate, setShowModalReactivate] = useState(false);
  const handleReactivateCancel = () => setShowModalReactivate(false);
  const handleReactivateSubmit = async (reactiveType = "remaining") => {
    //pause/reactivate subscription

    //set states if success
    if (subscriptionActive) {
      try {
        await pauseSubscription({ customerNo, subscriptionId: orderInfo.ID });
        fetchSubscriptionsData(customerNo);
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        await subscriptionReactivate({ customerNo, subscriptionId: orderInfo.ID, reactiveType });
        fetchSubscriptionsData(customerNo);
      } catch (err) {
        console.log(err);
      }
    }
    setSubscriptionActive(!subscriptionActive);
    setShowModalReactivate(false);
  };

  //modal for cancel subscription order
  const [showModalCancelOrder, setShowModalCancelOrder] = useState(false);
  const handleCancelOrderCancel = () => setShowModalCancelOrder(false);
  const handleCancelOrderSubmit = async () => {
    try {
      await subscriptionCancelRenewal({ customerNo, subscriptionId: orderInfo.ID });
      fetchSubscriptionsData(customerNo);
    } catch (err) {
      console.log(err);
    } finally {
      setShowModalCancelOrder(false);
    }
  };

  //get product by ID
  const getProductInfo = (productID: string) => {
    return productData && productData.length ? productData.find(item => item._id == productID) : undefined;
  };

  const getProductImage = (currentProduct: ProductsDataInterface | undefined) => {
    return currentProduct && currentProduct.images && currentProduct.images.length
      ? currentProduct.images[0]
      : sanitySiteSettings._rawProductFallbackImage;
  };

  return (
    <div className="subscription-order smb-24 smb-xl-36" key={key}>
      <div className="subscription-order-products">
        {orderInfo.products && orderInfo.products.length
          ? orderInfo.products.map((item: SubscriptionProductInterface, index: number) => {
              const currentProduct = getProductInfo(item.ID);
              const productImage = getProductImage(currentProduct);
              return (
                <div className="subscription-order-products__item" key={index}>
                  <div className="subscription-order-products__item-image">
                    <SanityImage
                      {...productImage}
                      width={90}
                      height={90}
                      htmlWidth={90}
                      htmlHeight={90}
                      className="img-fluid"
                      sizes={IMAGE_SIZES.TINY_SQUARE}
                      alt={productImage?.alt ? productImage?.alt : "product image"}
                    />
                  </div>

                  <div className="subscription-order-products__item-description">
                    <div className="highlighted">
                      x{item.quantity}{" "}
                      <span>
                        {item.ProductName ? item.ProductName : currentProduct ? currentProduct.name : item.ID}
                      </span>
                    </div>
                    <div className="refill-interval">
                      Refill Interval:{" "}
                      <span className="highlighted">
                        {item.interval} {item.periodicity}(s)
                      </span>
                    </div>
                  </div>
                </div>
              );
            })
          : null}
      </div>
      <div className="subscription-order-date">
        Subscription date: <span className="highlighted">{orderDate}</span>
        {orderInfo.status === "paused" ? <span> (Paused)</span> : null}
      </div>
      <div className="subscription-order__buttons">
        <Button
          variant="nonary"
          form="br-50"
          className="subscription-button subscription__modify"
          onClick={() => setShowModalModify(true)}
        >
          Modify
        </Button>
        <Button
          variant="nonary"
          form="br-50"
          className="subscription-button subscription__pause"
          onClick={() => setShowModalReactivate(true)}
        >
          {subscriptionActive ? "Pause" : "Reactivate"}
        </Button>
        <Button
          variant="nonary"
          form="br-50"
          className="subscription-button subscription__cancel"
          onClick={() => setShowModalCancelOrder(true)}
        >
          Cancel
        </Button>
      </div>
      <div className="subscription-order__upcoming-orders">{renderUpcomingOrders(orderInfo.ID, upcomingOrders)}</div>
      <ModalBlock
        showModal={showModalModify}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        ctaConfirm=""
        ctaCancel="Close"
      >
        <ModifyOrderBlock
          ID={orderInfo.ID}
          orderInfo={orderInfo}
          productData={productData}
          isSubscriptionOrder={true}
          locale={locale}
          customerNo={customerNo}
          fetchSubscriptionsData={fetchSubscriptionsData}
          handleModalClose={handleClose}
        />
      </ModalBlock>
      <ModalBlock
        showModal={showModalReactivate}
        handleSubmit={handleReactivateSubmit}
        handleClose={handleReactivateCancel}
        ctaConfirm={subscriptionActive ? "Yes" : ""}
        ctaCancel={subscriptionActive ? "No" : ""}
      >
        {subscriptionActive ? (
          <div className="text__size-h5">Are you sure you want to pause this subscription?</div>
        ) : (
          <ReactivateSubscription
            orderID={orderInfo.ID}
            orderInfo={orderInfo}
            ctaConfirm="Save"
            ctaCancel="Cancel"
            handleClose={handleReactivateCancel}
            handleSave={handleReactivateSubmit}
          />
        )}
      </ModalBlock>
      <ModalBlock
        showModal={showModalCancelOrder}
        handleSubmit={handleCancelOrderSubmit}
        handleClose={handleCancelOrderCancel}
        ctaConfirm="Yes, cancel"
        ctaCancel="No"
      >
        <div className="text__size-h5">Are you sure you want to cancel this subscription?</div>
      </ModalBlock>
    </div>
  );
};

export default SubscriptionBlock;
