import React, { FunctionComponent, useState, useEffect, useRef, useContext, MouseEvent } from "react";
// @ts-ignore
import { Modal } from "react-bootstrap";
import Button from "../../Button";
import { useSiteMetadata } from "../../../hooks/useSiteMetadata";
import { LocalizedContext } from "../../../services/LocalizedContextService";

import "./styles.scss";
export type ModalBlockInterface = {
  showModal: boolean;
  handleClose?: () => void;
  handleSubmit?: () => void;
  ctaConfirm: string;
  ctaCancel: string;
  isSecondary?: boolean;
};

const ModalBlock: FunctionComponent<React.PropsWithChildren<ModalBlockInterface>> = ({
  children,
  showModal,
  ctaConfirm,
  ctaCancel,
  handleClose,
  handleSubmit,
  isSecondary = false
}) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const { sanityLabels, language } = useContext(LocalizedContext);

  return (
    <Modal
      backdropClassName={isSecondary ? "backdrop--secondary" : ""}
      dialogClassName={isSecondary ? "modal--secondary" : ""}
      className={isSecondary ? "modal--secondary" : ""}
      show={showModal}
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      data-testid="modal"
    >
      <Modal.Header data-testid="modal-body">
        <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
          <span aria-hidden="true">&#10006;</span>
        </button>
      </Modal.Header>
      <Modal.Body data-testid="modal-body">
        <div>{children}</div>
      </Modal.Body>
      <Modal.Footer>
        {ctaCancel && (
          <Button variant="nonary" form="br-50" type="submit" onClick={handleClose}>
            {ctaCancel}
          </Button>
        )}
        {ctaConfirm && (
          <Button variant="nonary" form="br-50" type="submit" onClick={handleSubmit}>
            {ctaConfirm}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalBlock;
