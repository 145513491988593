import React, { useContext } from "react";
import { LocalizedContext } from "../../../../services/LocalizedContextService";
import Button from "../../../Button";
import "./styles.scss";

const AddressCard = ({
  orderID,
  addressType,
  address,
  handleChange
}: {
  orderID: string;
  addressType: string;
  address: any;
  handleChange: any;
}) => {
  const { ctaLabels: labels } = useContext(LocalizedContext).sanityLabels || {};

  if (!address) {
    return null;
  }

  const { addressId, firstName, lastName, address1, address2, city, stateCode, countryCode, postalCode, phone } =
    address;

  return (
    <div className="address-card__wrapper">
      <div className="address-card__user-info">
        <div className="row">
          <div className="highlited">Name:</div>
          <div>
            <span>{firstName}</span> <span>{lastName}</span>
          </div>
        </div>
        <div className="row">
          <div className="highlited">Address1: </div>
          <div>{address1}</div>
        </div>
        <div className="row">
          <div className="highlited">Address2: </div>
          <div>{address2}</div>
        </div>
        <div className="row">
          <div className="highlited">City: </div>
          <div>{city}</div>
        </div>
        <div className="row">
          <div className="highlited">State: </div>
          <div>{stateCode}</div>
        </div>
        <div className="row">
          <div className="highlited">ZIP: </div>
          <div>{postalCode}</div>
        </div>
        <div className="row">
          <div className="highlited">Country: </div>
          <div>{countryCode?.value || countryCode}</div>
        </div>
        <div className="row">
          <div className="highlited">Phone: </div>
          <div>{phone}</div>
        </div>
      </div>
      <div className="address-card-buttons">
        <Button
          variant="nonary"
          form="br-50"
          className="address-card__change"
          onClick={() => handleChange(address, addressType)}
        >
          Change Address
        </Button>
      </div>
    </div>
  );
};

export default AddressCard;
