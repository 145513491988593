import React, { useState } from "react";
import Button from "../../../Button";
import { AddressInterface, ProductsDataInterface } from "../../OrderHistory/index";
import ModalBlock from "../../Modal";
import ModifyOrderBlock from "./ModifyOrderBlock";
import { subscriptionSkipRenewal } from "../../../../services/salesforce/Subscription";
import { SubscriptionListItemInterface } from "..";

import "./styles.scss";

const UpcomingOrder = ({
  ID,
  createdAt,
  orderInfo,
  productData,
  locale,
  customerNo,
  billingAddress,
  shippingAddress,
  isSubscriptionOrder,
  fetchSubscriptionsData
}: {
  ID: string;
  originalOrder: string;
  createdAt: string;
  isSubscriptionOrder: boolean;
  productData: ProductsDataInterface[];
  orderInfo: SubscriptionListItemInterface;
  locale: string;
  customerNo: string;
  billingAddress: AddressInterface;
  shippingAddress: AddressInterface;
  fetchSubscriptionsData: (customerNo: string) => Promise<any>;
}) => {
  const orderDate = createdAt
    ? new Date(createdAt).toLocaleDateString("en-US", { year: "numeric", month: "long", day: "numeric" })
    : "";
  const [showModalModify, setShowModalModify] = useState(false);
  const handleClose = () => setShowModalModify(false);
  const handleSubmit = () => {
    setShowModalModify(false);
  };

  const [showModalSkipOrder, setShowModalSkipOrder] = useState(false);
  const handleSkipOrderCancel = () => setShowModalSkipOrder(false);
  const handleSkipOrderSubmit = async () => {
    try {
      await subscriptionSkipRenewal({ customerNo, oid: ID });
      fetchSubscriptionsData(customerNo);
    } catch (err) {
      console.log(err);
    } finally {
      setShowModalSkipOrder(false);
    }
  };

  return (
    <>
      <div className="upcoming-order__date highlighted">{orderDate}</div>
      <div className="upcoming-order__buttons">
        <Button
          variant="nonary"
          form="br-50"
          className="subscription-button upcoming-order__modify"
          onClick={() => setShowModalModify(true)}
        >
          Modify
        </Button>
        <Button
          variant="nonary"
          form="br-50"
          className=" subscription-button upcoming-order__skip"
          onClick={() => setShowModalSkipOrder(true)}
        >
          Skip
        </Button>
      </div>
      <ModalBlock
        showModal={showModalModify}
        handleSubmit={handleSubmit}
        handleClose={handleClose}
        ctaConfirm=""
        ctaCancel="Close"
      >
        <ModifyOrderBlock
          ID={ID}
          orderInfo={orderInfo}
          productData={productData}
          isSubscriptionOrder={isSubscriptionOrder}
          locale={locale}
          customerNo={customerNo}
          billingAddress={billingAddress}
          shippingAddress={shippingAddress}
          fetchSubscriptionsData={fetchSubscriptionsData}
          handleModalClose={handleClose}
        />
      </ModalBlock>
      <ModalBlock
        showModal={showModalSkipOrder}
        handleSubmit={handleSkipOrderSubmit}
        handleClose={handleSkipOrderCancel}
        ctaConfirm="Yes"
        ctaCancel="Cancel"
      >
        <div className="text__size-h5">Are you sure you want to skip this order?</div>
      </ModalBlock>
    </>
  );
};

export default UpcomingOrder;
