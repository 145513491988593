import axios from "axios";

const SALESFORCE_API_ENDPOINT =
  typeof window !== "undefined" &&
  (window.location.origin === "http://localhost:8000"
    ? "http://localhost:8888/.netlify/functions/"
    : window?.location?.origin + "/.netlify/functions/");

export const getAllSubscriptionOrders = async (customerNo: string) => {
  try {
    const response = await axios.post(SALESFORCE_API_ENDPOINT + "salesforce-subscription-orders", {
      customerNo
    });
    return {
      ...response.data
    };
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getSubscriptionDetailsById = async ({
  customerNo,
  subscriptionId
}: {
  customerNo: string;
  subscriptionId: string;
}) => {
  try {
    const response = await axios.post(SALESFORCE_API_ENDPOINT + "salesforce-subscription-list-view", {
      customerNo,
      subscriptionId
    });
    return {
      ...response.data
    };
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateRefillInterval = async ({
  customerNo,
  subscriptionId,
  periodicity,
  interval,
  item
}: {
  customerNo: string;
  subscriptionId: string;
  periodicity: string;
  interval: number;
  item: string;
}) => {
  try {
    const response = await axios.post(SALESFORCE_API_ENDPOINT + "salesforce-subscription-update-refill-interval", {
      customerNo,
      subscriptionId,
      periodicity,
      interval,
      item
    });
    return {
      ...response.data
    };
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const pauseSubscription = async ({
  customerNo,
  subscriptionId
}: {
  customerNo: string;
  subscriptionId: string;
}) => {
  try {
    const response = await axios.post(SALESFORCE_API_ENDPOINT + "salesforce-subscription-pause", {
      customerNo,
      subscriptionId
    });
    return {
      ...response.data
    };
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const subscriptionUpdateQuantity = async ({
  customerNo,
  subscriptionId,
  item,
  quantity,
  oid
}: {
  customerNo: string;
  subscriptionId?: string;
  item: string;
  quantity: number;
  oid?: string;
}) => {
  try {
    const response = await axios.post(SALESFORCE_API_ENDPOINT + "salesforce-subscription-update-quantity", {
      customerNo,
      subscriptionId,
      item,
      quantity,
      ...(subscriptionId ? { subscriptionId } : {}),
      ...(oid ? { oid } : {})
    });
    return {
      ...response.data
    };
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const subscriptionReactivate = async ({
  customerNo,
  subscriptionId,
  reactiveType
}: {
  customerNo: string;
  subscriptionId: string;
  reactiveType: string;
}) => {
  try {
    const response = await axios.post(SALESFORCE_API_ENDPOINT + "salesforce-subscription-reactivate", {
      customerNo,
      subscriptionId,
      reactiveType
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const subscriptionCancelRenewal = async ({
  customerNo,
  subscriptionId
}: {
  customerNo: string;
  subscriptionId: string;
}) => {
  try {
    const response = await axios.post(SALESFORCE_API_ENDPOINT + "salesforce-subscription-cancel-renewal", {
      customerNo,
      subscriptionId
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const subscriptionSkipRenewal = async ({ customerNo, oid }: { customerNo: string; oid: string }) => {
  try {
    const response = await axios.post(SALESFORCE_API_ENDPOINT + "salesforce-subscription-skip-renewal", {
      customerNo,
      oid
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const getSubscriptionSavedAddress = async ({
  customerNo,
  subscriptionId,
  item
}: {
  customerNo: string;
  subscriptionId: string;
  item: string;
}) => {
  try {
    const response = await axios.post(SALESFORCE_API_ENDPOINT + "salesforce-subscription-customer-saved-address", {
      customerNo,
      subscriptionId,
      item
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};

export const updateSubscriptionAddress = async ({
  customerNo,
  subscriptionId,
  item,
  address1,
  address2,
  city,
  countryCode,
  firstName,
  fullName,
  lastName,
  phone,
  postalCode,
  stateCode,
  valid,
  addressType,
  oid
}: {
  customerNo: string;
  subscriptionId?: string;
  item: string;
  address1: string;
  address2: string;
  city: string;
  countryCode: string;
  firstName: string;
  fullName: string;
  lastName: string;
  phone: string;
  postalCode: string;
  stateCode: string;
  valid: string;
  addressType: string;
  oid?: string;
}) => {
  try {
    const response = await axios.post(SALESFORCE_API_ENDPOINT + "salesforce-subscription-update-address", {
      customerNo,
      subscriptionId,
      item,
      address1,
      address2,
      city,
      countryCode,
      firstName,
      fullName,
      lastName,
      phone,
      postalCode,
      stateCode,
      valid,
      addressType,
      ...(subscriptionId ? { subscriptionId } : {}),
      ...(oid ? { oid } : {})
    });
    return response;
  } catch (err) {
    console.log(err);
    return err;
  }
};
