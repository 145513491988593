import React, { FunctionComponent, useState, useEffect, useContext } from "react";
import { getProductsByID } from "../../../services/ProductById";
import { AddressInterface } from "../AddressBook";
import SubscriptionBlock from "./SubscriptionOrderBlocks/SubscriptionBlock";
import { ProductsDataInterface } from "../OrderHistory/index";
import { getAllSubscriptionOrders, pauseSubscription } from "../../../services/salesforce/Subscription";
import { useQuery } from "react-query";
import { getUserProfile } from "../../../services/salesforce/Account/user";
import { getAuthUser } from "../../../services/salesforce/Auth";

export type SubscriptionProductInterface = {
  ID: string;
  price: string;
  currencyCode: string;
  quantity: number;
  periodicity: string;
  interval: number;
  orderDay: number;
  variation?: string;
  ProductName?: string;
};

export interface SubscriptionAddressInterface {
  ID?: string;
  addressId?: string;
  address1: string;
  address2: string;
  firstName: string;
  lastName: string;
  countryCode: string | { displayValue: string; value: string };
  city: string;
  postalCode: string;
  stateCode: string;
  phone: string;
}

export type SubscriptionListItemInterface = {
  ID: string;
  subscriptionID: string;
  originalOrder: string;
  //type: "SOR",
  renewal?: boolean;
  status: string;
  customerNo: string;
  createdAt: string;
  orderDay: number;
  periodicity?: string;
  interval?: number;
  lastRefillDate: string;
  validUntil: string;
  billingAddress?: AddressInterface;
  shippingAddress?: AddressInterface;
  paymentMethod: string;
  products: SubscriptionProductInterface[];
  creditCardToken: string;
  isPayPal: boolean;
  cardExpirationDate: string;
  lastUpdate: string;
};

export type CreditCardInterface = {
  number: string;
  expMonth: string;
  holderName: string;
  expYear: string;
  type: string;
};

export type SubscriptionOrderInterface = {
  locale: string;
  CustomerNo: string;
  ordersList: SubscriptionListItemInterface[];
  ProductList: SubscriptionListItemInterface;
  viewType?: string;
  currentCreditCard?: CreditCardInterface;
  ShippingMethodName?: string;
  ShippingCost?: number;
};

export type SubscriptionsListInterface = {
  ID: string;
  originalOrder: string;
  //type: "SOR",
  renewal?: boolean;
  status: string;
  customerNo: string;
  createdAt: string;
  orderDay: number;
  periodicity?: string;
  interval?: number;
  lastRefillDate: string;
  validUntil: string;
  billingAddress: AddressInterface;
  shippingAddress: AddressInterface;
  paymentMethod: string;
  products: SubscriptionProductInterface[];
  creditCardToken: string;
  isPayPal: boolean;
  cardExpirationDate: string;
  lastUpdate: string;
};

const Subscriptions = () => {
  const [productData, setProductData] = useState<ProductsDataInterface[]>();
  const [subscriptionsData, setSubscriptionData] = useState({});
  const [subscrArray, setSubcrArray] = useState([]);

  const getUpcomungOrdersList = (ID: string) => {
    const upcomingOrdersList =
      subscriptionsData && subscriptionsData?.OrderListObject && subscriptionsData?.OrderListObject[ID]
        ? [...subscriptionsData?.OrderListObject[ID]]
        : null;
    const newUpcomingOrdersLists = upcomingOrdersList?.map(upcomingOrderItem => {
      if (productData && productData.length) {
        const productId = upcomingOrderItem.products.length ? upcomingOrderItem.products[0]["ID"] : "";
        const currentProductData = productData.find(item => item._id === productId);
        return {
          ...currentProductData,
          ...upcomingOrderItem
        };
      }
      return upcomingOrderItem;
    });
    newUpcomingOrdersLists?.sort((a, b) => new Date(a?.createdAt) - new Date(b?.createdAt));
    return newUpcomingOrdersLists || [];
  };

  const getProductIDs = (subscrArray: any) => {
    if (!(subscrArray && subscrArray.length)) return;
    const arrayOfID: string[] = [];
    if (subscrArray && subscrArray.length) {
      subscrArray.forEach((order: any) => {
        if (order.products && order.products.length) {
          order.products.forEach((item: any) => {
            item.ID && arrayOfID.push(item.ID);
          });
        }
      });
    }

    return arrayOfID;
  };

  async function fetchData(subscrArray: any) {
    const ids = getProductIDs(subscrArray);
    try {
      const response = await getProductsByID(ids, true);
      setProductData(response?.data);
    } catch (err) {
      console.log(err);
    }
  }

  const user = getAuthUser();

  const { data: userProfile } = useQuery({
    queryFn: () => getUserProfile(user.rcid),
    queryKey: "profile"
  });

  const fetchSubscriptionsData = async (customerNo: string) => {
    try {
      const response = await getAllSubscriptionOrders(customerNo);
      if (response) {
        setSubscriptionData(response);
        const newSubscrArray = Object.keys(response.SubscriptionsList).map(key => {
          return response && response.SubscriptionsList && response.SubscriptionsList[key]
            ? response.SubscriptionsList[key]
            : null;
        });

        const activeSubscriptions =
          newSubscrArray && newSubscrArray.length ? newSubscrArray.filter(item => item?.renewal) : [];

        setSubcrArray([...activeSubscriptions]);
      }
      return response;
    } catch (err) {
      console.log(err);
      return err;
    }
  };

  useEffect(() => {
    fetchData(subscrArray);
  }, [subscrArray]);

  useEffect(() => {
    if (userProfile && userProfile.customerNo) {
      fetchSubscriptionsData(userProfile.customerNo);
    }
  }, [userProfile]);

  return (
    <div className="subscriptions">
      {subscrArray && subscrArray.length ? (
        <div className="order-history__list">
          {subscrArray.map((item: SubscriptionsListInterface, key: number) => {
            return (
              <SubscriptionBlock
                key={key}
                orderInfo={item}
                upcomingOrders={getUpcomungOrdersList(item.ID)}
                productData={productData || item.products}
                locale={subscriptionsData.locale}
                fetchSubscriptionsData={fetchSubscriptionsData}
                {...item}
              />
            );
          })}
        </div>
      ) : (
        "No subscriptions yet."
      )}
    </div>
  );
};

export default Subscriptions;
