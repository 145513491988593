import React, { useState, useContext } from "react";
import { ProductsDataInterface } from "../../OrderHistory/index";
import { SubscriptionProductInterface } from "..";
import ProductCard from "./ProductCard";
import "./styles.scss";

const ProductBlock = ({
  subscriptionOrderID,
  orderProducts,
  productData,
  isSubscriptionOrder,
  locale,
  shippingMethod,
  shippingCost,
  customerNo,
  fetchSubscriptionsData,
  handleModalClose
}: {
  subscriptionOrderID: string;
  orderProducts: SubscriptionProductInterface[];
  productData: ProductsDataInterface[];
  isSubscriptionOrder: boolean;
  locale: string;
  shippingMethod: string;
  shippingCost: number;
  customerNo: string;
  fetchSubscriptionsData: (customerNo: string) => Promise<any>;
  handleModalClose: () => void;
}) => {
  const formatMoneyAmount = (amount: number, customerLocale: string, currency: string) => {
    return amount
      ? new Intl.NumberFormat(customerLocale.replace("_", "-"), {
          style: "currency",
          currency: currency
        }).format(+amount)
      : `$0.00`;
  };

  const getOrderSubtotal = () => {
    return orderProducts && orderProducts.length
      ? orderProducts.reduce((accumulator, currentValue) => {
          console.log({ currentValue });
          return accumulator + Number(currentValue.price) * Number(currentValue.quantity);
        }, 0)
      : 0;
  };

  return (
    <div className="subscription-products__wrapper">
      <div className="grid-row">
        <div className="product-title smb-16 highlighted">Product</div>
        <div className="column-title highlighted">Image</div>
        <div className="column-title highlighted">Quantity</div>
        <div className="column-title highlighted">Refill Interval</div>
        <div className="column-title highlighted">Price</div>
      </div>
      {orderProducts && orderProducts.length
        ? orderProducts.map((item: SubscriptionProductInterface, key: number) => {
            return (
              <ProductCard
                key={key}
                orderProduct={item}
                productData={productData.find(el => el._id === item.ID)}
                isSubscriptionOrder={isSubscriptionOrder}
                locale={locale}
                customerNo={customerNo}
                fetchSubscriptionsData={fetchSubscriptionsData}
                subscriptionId={subscriptionOrderID}
                handleModalClose={handleModalClose}
              />
            );
          })
        : null}
      <div className="subscription-products__total">
        <div className="grid-row">
          <div className="highlighted">Subtotal</div>
          <div className="">{formatMoneyAmount(getOrderSubtotal(), locale, "USD")}</div>
        </div>
        <div className="grid-row">
          <div className="highlighted ">Shipping ({shippingMethod})</div>
          <div className="">{formatMoneyAmount(shippingCost, locale, "USD")}</div>
        </div>

        <div className="subscription-products__total-tax">Tax will be calculated when the order will be placed</div>

        <div className="grid-row">
          <div className="highlighted">Total</div>
          <div className="">{formatMoneyAmount(getOrderSubtotal() + shippingCost, locale, "USD")}</div>
        </div>
      </div>
    </div>
  );
};

export default ProductBlock;
