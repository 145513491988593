import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Field from "../../../Forms/Field";
import Button from "../../../Button";
import "./styles.scss";
import { sendErrorEvents } from "../../../../utils/sendErrorEvents";

const ReactivateSubscription = ({
  orderID,
  ctaConfirm,
  ctaCancel,
  handleClose,
  handleSave
}: {
  orderID: string;
  ctaConfirm: string;
  ctaCancel: string;
  handleClose: () => void;
  handleSave: (reactiveType?: string) => void;
}) => {
  //need to check if reactivation possible for this order (orderID)( from API )
  //!!!!!!!! CHECK data
  const orderReactivateInfo = {
    action: "Headless-ManageRefillList",
    queryString: "action=reactivate&customerID=DEVSMUS00109012&sid=SOR-1-DEVSMUS00028547",
    locale: "en_US",
    ContinueURL: {},
    Available: true,
    RemainingDate: "2024-04-06T09:01:50.680Z",
    OneYearDate: "Sat Apr 27 2024",
    PriceChanges: {
      changed: false,
      products: [
        {
          ID: "764302290209",
          currencyCode: "USD",
          oldPrice: "11.99",
          newPrice: "13.99",
          ProductName: "Coconut & Hibiscus Curl & Shine Shampoo"
        }
      ]
    }
  };

  const currentDate = new Date();
  const remainingDate = new Date(
    currentDate.getFullYear() + 1,
    currentDate.getMonth(),
    currentDate.getDate()
  ).toLocaleDateString("en-US", {
    weekday: "short",
    year: "numeric",
    month: "long",
    day: "numeric"
  });

  const formRadioField = {
    type: "radioGroup",
    name: "reactivationPeriod",
    rules: {
      required: true
    },
    options: [
      {
        label: `Remaining Period (Ends on ${remainingDate})`,
        value: "remaining",
        defaultChecked: true
      },
      { label: `One Year (Ends on ${remainingDate})`, value: "year" }
    ]
  };

  const displayProductsWithChangedPrice = (orderReactivateInfo: any) => {
    return (
      <div className="changed-price smb-24 smb-lg-36">
        <div>
          The price has changed for one or more items that are part of your subscription. These changes will be applied
          as soon as your subscription is reactivated.
        </div>
        <div className="grid-row">
          <div className="highlighted">Product</div>
          <div className="highlighted">Old Price</div>
          <div className="highlighted">New Prioce</div>
        </div>
        {orderReactivateInfo.products.map((item: any, index: number) => {
          return (
            <div className="grid-row" key={index}>
              <div className="">{item.ProductName}</div>
              <div className="">{item.oldPrice}</div>
              <div className="">{item.newPrice}</div>
            </div>
          );
        })}
      </div>
    );
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();

  useEffect(() => {
    reset();
  }, []);

  const onSubmit = (data: any) => {
    const { reactivationPeriod } = data;
    handleSave(reactivationPeriod);
  };

  const handleSubmitClick = () => {
    sendErrorEvents(errors, [formRadioField]);
  };

  return (
    <>
      {orderReactivateInfo && orderReactivateInfo.Available ? (
        <div className="subscription-reactivation">
          <div className="text__size-h5 smb-24 smb-lg-36">For how long you want to reactivate your subscription?</div>
          <form onSubmit={handleSubmit(onSubmit)} className="reactivation-period">
            <div className="form-group">
              <Field key={formRadioField.name} {...formRadioField} registerInput={register} />
              <div className="col-md">
                <ErrorMessage errors={errors} name={formRadioField.name} render={({ message }) => <p>{message}</p>} />
              </div>
            </div>

            {orderReactivateInfo.PriceChanges &&
            orderReactivateInfo.PriceChanges.changed &&
            orderReactivateInfo.PriceChanges.products &&
            orderReactivateInfo.PriceChanges.products.length
              ? displayProductsWithChangedPrice(orderReactivateInfo)
              : null}

            <div className="subscription-reactivation-buttons">
              <Button variant="nonary" form="br-50" className="subscription-reactivation__cancel" onClick={handleClose}>
                {ctaCancel}
              </Button>
              <Button
                className="subscription-reactivation__submit"
                type="submit"
                variant="nonary"
                form="br-50"
                onClick={handleSubmitClick}
              >
                {ctaConfirm}
              </Button>
            </div>
          </form>
        </div>
      ) : (
        <div>Sorry! This order cannot be reactivated.</div>
      )}
    </>
  );
};

export default ReactivateSubscription;
