import React, { ChangeEvent, useContext, useState } from "react";
import { LocalizedContext } from "../../../../services/LocalizedContextService";
import SanityImage from "gatsby-plugin-sanity-image";
import Button from "../../../Button";
import { SubscriptionProductInterface } from "../index";
import { subscriptionUpdateQuantity, updateRefillInterval } from "../../../../services/salesforce/Subscription/";

import "./styles.scss";
import { IMAGE_SIZES } from "../../../../constants";

const ProductCard = ({
  key,
  orderProduct,
  productData,
  isSubscriptionOrder,
  locale,
  customerNo,
  subscriptionId,
  fetchSubscriptionsData,
  handleModalClose
}: {
  key: number;
  orderProduct: SubscriptionProductInterface;
  productData?: any;
  isSubscriptionOrder: boolean;
  locale: string;
  customerNo: string;
  subscriptionId: string;
  fetchSubscriptionsData: (customerNo: string) => Promise<any>;
  handleModalClose: () => void;
}) => {
  const { sanitySiteSettings, sanityLabels } = useContext(LocalizedContext);
  const refillIntervals = sanityLabels?.productLabels?.deliverySubscription?.frequency || [];

  const formatMoneyAmount = (amount: number, customerLocale: string, currency: string) => {
    return new Intl.NumberFormat(customerLocale.replace("_", "-"), {
      style: "currency",
      currency: currency
    }).format(+amount);
  };

  const getCurrentRefill = () => {
    return (orderProduct.periodicity === "week" ? "w" : "m") + "-" + orderProduct.interval || "w-1";
  };

  const [productQuantity, setProductQuantity] = useState<number>(orderProduct.quantity);
  const [productRefillInterval, setProductRefillInterval] = useState(() => getCurrentRefill());

  const updateQuantity = async () => {
    try {
      const { ID } = orderProduct;
      await subscriptionUpdateQuantity({
        customerNo,
        item: ID,
        quantity: productQuantity,
        ...(isSubscriptionOrder ? { subscriptionId } : { oid: subscriptionId })
      });
      fetchSubscriptionsData(customerNo);
    } catch (err) {
      console.log(err);
    } finally {
      handleModalClose();
    }
  };

  const updateRefill = async () => {
    let periodicity = "week";
    let interval = 1;
    interval = Number(productRefillInterval.replace(/[^0-9]/gi, "")) || 1;
    periodicity = productRefillInterval.replace(/[^a-zA-Z]/gi, "") === "m" ? "month" : "week";
    try {
      const { ID } = orderProduct;
      await updateRefillInterval({
        customerNo,
        subscriptionId,
        periodicity,
        item: ID,
        interval
      });
      fetchSubscriptionsData(customerNo);
    } catch (err) {
      console.log(err);
    } finally {
      handleModalClose();
    }
  };

  const orderImage =
    productData && productData.images && productData.images.length
      ? productData.images[0]
      : sanitySiteSettings._rawProductFallbackImage;

  const handleQuantityChange = (event: ChangeEvent<HTMLInputElement>) => {
    setProductQuantity(Number(event.target.value));
  };

  const handleRefillChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setProductRefillInterval(event.target.value);
  };

  return (
    <div className="subscription-product__wrapper grid-row " key={`product-${key}`}>
      <div className="subscription-product__name grid-col grid-col-xs-12">
        {productData ? productData.name : orderProduct.ID}
      </div>
      <div className="subscription-product__image grid-col grid-col-xs-6 grid-col-md-3">
        <SanityImage
          {...orderImage}
          width={90}
          height={90}
          htmlWidth={90}
          htmlHeight={90}
          className="img-fluid"
          sizes={IMAGE_SIZES.TINY_SQUARE}
          alt={orderImage?.alt ? orderImage?.alt : "product image"}
        />
      </div>
      <div className="subscription-product__quantity  grid-col grid-col-xs-6 grid-col-md-3">
        <input
          type="number"
          className="subscription-product__quantity-select smb-24"
          onChange={handleQuantityChange}
          min={0}
          value={productQuantity}
        />
        <Button
          className="subscription-product__quantity-submit"
          type="submit"
          variant="nonary"
          form="br-50"
          onClick={updateQuantity}
        >
          Update Quantity
        </Button>
      </div>
      <div className="subscription-product__refill grid-col grid-col-xs-6 grid-col-md-3 ">
        {isSubscriptionOrder ? (
          <>
            {refillIntervals && refillIntervals.length && (
              <select
                className="subscription-product__refill-select  smb-24"
                onChange={handleRefillChange}
                value={productRefillInterval}
              >
                {refillIntervals.map((item: any, index: number) => (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                ))}
              </select>
            )}
            <Button
              className="subscription-product__refill-submit"
              type="submit"
              variant="nonary"
              form="br-50"
              onClick={updateRefill}
            >
              Update Interval
            </Button>
          </>
        ) : (
          <div> - </div>
        )}
      </div>
      <div className="subscription-product__price grid-col grid-col-xs-6 grid-col-md-3">
        {formatMoneyAmount(Number(orderProduct.price), locale, "USD")}
      </div>
    </div>
  );
};

export default ProductCard;
