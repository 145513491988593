import React, { ChangeEvent, useState, useEffect } from "react";
import { SubscriptionAddressInterface } from "../index";
import AddressForm from "./AddressEditForm";

import "./styles.scss";

const AddressEdit = ({
  orderID,
  data,
  addressType,
  handleCancel,
  closeOnSubmit,
  customerNo,
  fetchSubscriptionsData,
  isSubscriptionOrder
}: {
  orderID: string;
  data: SubscriptionAddressInterface;
  addressType: string;
  handleCancel: any;
  closeOnSubmit: () => void;
  customerNo: string;
  isSubscriptionOrder?: boolean;
  fetchSubscriptionsData: (customerNo: string) => Promise<any>;
}) => {
  const [userAddress, setUserAddress] = useState<SubscriptionAddressInterface>(data);

  //get saved addresses of this user (address from profile)
  const savedAddresses = [data];

  const [selectedAddress, setSelectedAddress] = useState("");

  const handleAddressChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedAddress(event.target.value);
  };

  useEffect(() => {
    const chosenAddress = savedAddresses.find(item => (item.ID = selectedAddress));
    chosenAddress ? setUserAddress(chosenAddress) : setUserAddress(data);
  }, [selectedAddress]);

  const addressTitle = addressType.toLowerCase() === "shipping" ? "Shipping Address" : "Billing Address";

  return (
    <div className="address-form">
      <div className="text__size-h4 smb-24">{addressTitle}</div>
      <select className="subscription-address smb-24 smb-lg-36" onChange={handleAddressChange} value={selectedAddress}>
        <option value="" key={1}>
          Select from Saved Addresses
        </option>
        {savedAddresses && savedAddresses.length
          ? savedAddresses.map((item: SubscriptionAddressInterface, index: number) => {
              return (
                <option value={item.addressId} key={index + 1}>
                  {item.city}
                </option>
              );
            })
          : null}
      </select>
      <AddressForm
        orderID={orderID}
        data={userAddress}
        addressType={addressType}
        handleCancel={handleCancel}
        closeOnSubmit={closeOnSubmit}
        customerNo={customerNo}
        fetchSubscriptionsData={fetchSubscriptionsData}
        isSubscriptionOrder={isSubscriptionOrder}
      />
    </div>
  );
};

export default AddressEdit;
